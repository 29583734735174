import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const AssessmentsMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "AssessmentAssessmentNavigationTitle",
        path: "/admin/assessments",
        page: "Assessments",
        subpage: "Assessments",
      },
    ]}
  />
);

export default AssessmentsMenu;
